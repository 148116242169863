import React, {useState, useRef, useEffect} from 'react';

import i18n from '../../i18n';
import PropTypes from "prop-types";
import styles from './SearchModal.module.scss';
import SearchInputForm from "../SearchInputForm";
import Icon from "../Icon";

const SearchModal = ({searchUrl, closeModal, headerNavRef}) => {
    const [query, setQuery] = useState('');

    const modalRef = useRef()

    useEffect(() => {
        const listener = (event) => {
            // Close modal on outside click, excluding Header search button
            if (!modalRef.current ||
                modalRef.current.contains(event.target) ||
                !headerNavRef.current ||
                headerNavRef.current.contains(event.target)
            ) {
                return;
            }
            closeModal()
        };

        const closeOnEscape = (e) => {
            if (e.keyCode === 27) { // 27 is Escape
                closeModal()
            }
        };

        document.addEventListener('keyup', closeOnEscape);

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [modalRef]);

    const removeSearch = () => {
        setQuery("")
        document.querySelector('#search_input').focus()
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!query) {
            return
        }

        window.location.href=(`${searchUrl}?q=${query}`)
    }

    return (
        <div className={styles['SearchModal']}>
            <div className={styles['SearchModal__Inner']} ref={modalRef}>
                <div className={styles['SearchModal__Container']}>
                    <div className={styles['SearchModal__Wrapper']}>
                        <div className={styles['SearchModal__InputWrapper']}>
                            <SearchInputForm
                                onChange={setQuery}
                                value={query}
                                onSubmit={onSubmit}
                                removeSearch={removeSearch}
                            />
                        </div>
                        <button
                            className={styles['SearchModal__CloseModal']}
                            onClick={closeModal}
                        >
                            {i18n.t("SearchModal.close")}
                            <Icon type={"close"}
                                  size={"fill"}
                                  modifiers={[styles['SearchModal__CloseIcon']]}
                                  color={"gray10"}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

SearchModal.propTypes = {
    searchUrl: PropTypes.string,
    closeModal: PropTypes.func,
    headerNavRef: PropTypes.shape({
        current: PropTypes.any
    })
};

SearchModal.defaultProps = {
    searchUrl: "",
    closeModal: () => {},
    headerNavRef: null
};

export default SearchModal;
